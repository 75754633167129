import axios from "axios";


export default {

  login(user, headers){
    return axios.post('/login', user, headers);
  },
  login_barragan(user, headers){
    return axios.post('/login_barragan', user, headers);
  },

  getInfoUser(data, headers){
    return axios.post('/info_usr', data, {headers});
  },


  getAllRange(payload, headers){ //traer todos por rango de fecha
    return axios.post(`/${payload.option}`, payload.data, {headers});
  },


  getAllInfo(option, headers){
    return axios.get(`/${option}`, {headers});
  },
  getInfoById(payload, headers){
    return axios.get(`/${payload.option}/${payload.id}`, {headers});
  },
 
  search(data, headers){
    return axios.get(`/buscar/${data.normalizedOpt}/${data.search}`, {headers});
  },

  searchByClient(data, headers){
    return axios.post(`/buscar/${data.option}`, data, {headers});
  },

  //metodos de eliminar, editar y agregar
  deleteItem(payload,token){
    return axios.delete(`/${payload.option}/delete`, {
        data: {
          id:payload.id
        },
        headers: {
          Authorization: token 
        } 
      });
  },
  
  editItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.put(`/${payload.option}/update`, payload.item, {headers} );
  },

  addItem(payload,token){
    let  headers = {
      Authorization: token
    }
    return axios.post(`/${payload.option}/create`, payload.item, {headers});
  },

  enviarMailContacto(data, headers){
    return axios.post('s43-wprDK6s_wfcs7/contacto', data, headers);
  },


  

};