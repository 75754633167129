import axios from "axios";
let urlpath = require('../global/url')
import dayjs from 'dayjs';



var userToken="";

export default {
  
  
  setToken(token) {
    localStorage.setItem("tkn", token);
  },

  getToken() {
    let token = localStorage.getItem("tkn");

    if (token && token != "undefined" && token != '') {
      userToken = token;
    } else {
      userToken = null;
    }

    return userToken;
  },


  isAdmin(user){
    let admin = false;
    let type = 'user'

    if(user == undefined || user == null || user == ''){
      return {"admin":admin, "type":type}
    }

    if(user.role == undefined || user.role == null || user.role ==''){
      return {"admin":admin, "type":type}
    }

    if(user.role != 'admin'  && user.role != 'spadmin' && user.role != 'legrafica' && user.role != 'recepcionista' && user.role != 'analista'){
      return {"admin":admin, "type":type}
    }

    //si es admin.
    admin = true
    type = user.role

     return {"admin":admin, "type":type}


  },
  isClient(user){
    let cli = false;
    let type = 'user'

    if(user == undefined || user == null || user == ''){
      return {"cli":cli, "type":type}
    }

    if(user.role == undefined || user.role == null || user.role ==''){
      return {"cli":cli, "type":type}
    }

    if(user.role != 'cliente'){
      return {"cli":cli, "type":type}
    }

    //si es cli.
    cli = true
    type = user.role

     return {"cli":cli, "type":type}


  },

  isSuc(user){
    let suc = false;
    let type = 'user'

    if(user == undefined || user == null || user == ''){
      return {"suc":suc, "type":type}
    }

    if(user.role == undefined || user.role == null || user.role ==''){
      return {"suc":suc, "type":type}
    }

    if(user.role != 'sucursal'){
      return {"suc":suc, "type":type}
    }

    //si es suc.
    suc = true
    type = user.role

     return {"suc":suc, "type":type}


  },


  async tokenexp(){  
    let exp = false;


    
   let response = await this.getData()

   if(response.status =='error'){
     return true
   }
    
   let identity = response.result;

   let identity_exp = identity.exp; // fecha de expiración
   let date = dayjs().format(); // fecha actual
   date < identity_exp? exp : exp = true; 

   
    return exp;
  },


  

  async getData(){ 
 

    try {              
      

      let token = this.getToken()

      if(token==null){
          return {
              status:'error',
              message:'Sesión no valida',
              result:''
          }
      }


      let headers =  {'Authorization': token} ;
    
      let response = await axios.post('/info_usr', {"data":token}, {headers});
    
      
      return response.data;

      } catch (error) {
          return {
            status:'error',
            message:'Error al intentar obtener la sesión.',
            result:''
        }
      }

} 




};

