import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";
import userService from '../services/user.service'

Vue.use(VueRouter)

const routes = [
  {
    path: '/administrador',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta :{
      log:true,
      admin : true,
      cli: false,
      suc:false,
      auth: false
    }
  },
  {
    path: '/administrador/ticket',
    name: 'ticket',
    component: () => import('../components/barragan/ticket'),
    meta :{
      log:true,
      admin : true,
      cli: false,
      suc:false,
      auth: false
    }
  },
  {
    path: '/administrador/etiqueta',
    name: 'etiqueta',
    component: () => import('../components/barragan/etiqueta'),
    meta :{
      log:true,
      admin : true,
      cli: false,
      suc:false,
      auth: false
    }
  },
  {
    path: '/solicitud',
    name: 'solicitud',
    component: () => import('../components/barragan/solicitud'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },
  {
    path: '/cliente/administrador',
    name: 'DashboardCliente',
    component: () => import('../views/DashboardCli'),
    meta :{
      log:true,
      admin : false,
      cli: true,
      auth: false
    }
  },
  {
    path: '/cliente/sucursal/administrador',
    name: 'DashboardSucursal',
    component: () => import('../views/DashboardSuc'),
    meta :{
      log:true,
      admin : false,
      cli: false,
      suc: true,
      auth: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    beforeEnter: async (to, from, next) => { 
      let tokenexp = await userService.tokenexp()

      if(tokenexp){
        localStorage.removeItem('tkn'); //limpiar el localstorage
        next()
      }
     
      next();  
    },
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },
 
 /**
    {
    path: '/',
    name: 'home',
    component: () => import('../views/Home'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },
  */
 
  {
    path: '/pdfclienteInf',
    name: 'PDFclienteInf',
    component: () => import('../views/clientes/PDFclienteInf'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/Login'),
    beforeEnter: async (to, from, next) => { 
      let tokenexp = await userService.tokenexp()

      if(tokenexp){
        localStorage.removeItem('tkn'); //limpiar el localstorage
        next()
      }
     
      next();  
    },
    meta :{
     log:false,
     admin : false,
     cli: false,
     suc:false,
     auth: false
    }
  },

 /*
  

  {
    path: '/barragan/error',
    name: 'barragannotfound',
    component: () => import('../views/error/ErrorBarragan'),
    meta :{
     log:false,
     admin : false,
     cli: false,
     suc:false,
     auth: false
    }
  },

  {
    path: '/laason/error',
    name: 'laasonnotfound',
    component: () => import('../views/error/ErrorLaason'),
    meta :{
     log:false,
     admin : false,
     cli: false,
     suc:false,
     auth: false
    }
  },

  {
    path: '/proximamente',
    name: 'Proximamente',
    component: () => import('../views/Proximamente'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },

  {
    path: '/barragan/politica',
    name: 'PoliticaBarragan',
    component: () => import('../views/politicas/PoliticasBarragan'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },
  
  {
    path: '/laason/politica',
    name: 'PoliticaLaason',
    component: () => import('../views/politicas/PoliticasLaason'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },

  {
    path: '/barragan/servicios',
    name: 'ServiciodBarragan',
    component: () => import('../views/servicios/ServiciosBarragan'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },

  {
    path: '/barragan',
    name: 'HomeBarragan',
    component: () => import('../views/HomeBarragan'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },
 

  {
    path: '/laason',
    name: 'Homelaason',
    component: () => import('../views/Laason'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },

  {
    path: '/laason/servicios',
    name: 'servicioscreen',
    component: () => import('../views/servicios/ServicioScreen'),
    meta :{
      log:false,
      admin : false,
      cli: false,
      suc:false,
      auth: false
    }
  },
 
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test'),
    meta :{
     log:false,
     admin : false,
     cli: false,
     suc:false,
     auth: false
    }
  },

  {
    path: '/proximamente',
    name: 'test',
    component: () => import('../views/test'),
    meta :{
      log:false,
      admin : false,
      auth: false
    }
  }, */
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



router.beforeEach( async (to, from, next) => {

  let meta_admin = to.matched.some(record=>record.meta.admin);
  let meta_log = to.matched.some(record=>record.meta.log);
  let meta_auth = to.matched.some(record=>record.meta.auth);
  let meta_cli = to.matched.some(record=>record.meta.cli);
  let meta_suc = to.matched.some(record=>record.meta.suc);

  let tokenexp = await userService.tokenexp()
  let check =false //comprobar si esta logeado
  let admin =false;
  let type =''
  let cliente = false;
  let typeCli = 'principal';
  let sucursal = false;

  if(tokenexp){ // ver que el token no este expirado.
    localStorage.removeItem('tkn'); //limpiar el localstorage
    next()
  }


  let response = await userService.getData()

  if(response.status =='error'){
    localStorage.removeItem('tkn')
  }


  if(response.status=='success'){ //comprobar que sea un login valido.
    check =true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin
    type = isAdmin.type

    let isCliente = userService.isClient(response.result);

    cliente = isCliente.cli;
    typeCli = isCliente.type;

    let isSuc = userService.isSuc(response.result);

    sucursal= isSuc.suc;
  
  }

 
  // borrar el localstorage cada que vayamos a login.
  
  if(to.path==='/login' && check){
    localStorage.removeItem('tkn')
    next()
  }

 
  if(meta_log){
    if(check){
      if(meta_admin){
            if(admin){
              /* activar si se requiere autentificacion de dos pasos.
              if(meta_auth){
                if(ath){
                  next();
                }else{
                 // next('/auth') // mientras se arregla lo del envio de emails.
                  next('/login')
                }
              }else{ //...proximamente rutas que no requieran autentificacion
                
              }*/
              next()
            }else{
              localStorage.removeItem('tkn')
              next('/')
            }
      }else if(meta_cli){
       
          if(cliente){
            next()
          }else{
            localStorage.removeItem('tkn')
            next('/')
          }

        
       
      //else if meta_sucursa
      }else if(meta_suc){

        if(sucursal){
          next()
        }else{
          localStorage.removeItem('tkn')
          next('/')
        }

      }else{
        if(admin){ //no requiere admin pero es admin
          next()
         }else{
           next()
         }
      }
    }else{
      next('/login')
    }
    

  }else{
    if(check){
      if(admin){
       next()
      }else{
        next();
      }
    }else{
      next();
    }

  }

 
});






let users = async function(id){ //validar que no entren a la ruta de editar usuarios especificos
 
  let payload = {
    id: id,
    option: "user",
  };
  try {              
    let token = userService.getToken() 
    let headers =  {'Authorization': token} ;
  
    let response = await axios.get(`/${payload.option}/${payload.id}`, {headers});
   
    
    return response.data.result;
    } catch (error) {
        console.log(error);
        
    }

} 






export default router

